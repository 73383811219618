body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.list_categories > div > div.MuiBox-root {
  width: 250px;
}

.list_categories > div > div.MuiBox-root .MuiButtonBase-root {
  font-size: 10px;
}

.list_categories > div:nth-last-child(n + 2) > div.MuiBox-root {
  width: 100px;
}

.list_categories > div:nth-last-child(n + 2) > form {
  display: none;
}

.list_categories > div:nth-last-child(n + 2) > div.MuiBox-root .MuiButtonBase-root {
  font-size: 8px;
}

.product-visit-text-area {
  height: 150px !important;
}


.locationsFormContainer {
  margin-left: 20px;
  margin-top: 20px;
}

.rapportsCheckboxContainer label {
  margin-left: 10px;
}

.rapportsAllotmentsContainer .rapportsLocationsContainer {
  margin-left: 20px;
}

.rapportsLocationsContainer .rapportsVisitsContainer {
  margin-left: 20px;
}