.root {
    display: flex;
    flex-grow: 1;
}

.paper {
    padding: 2;
    text-align: 'center';
    color: '#ff7961';
}

.container {
    padding-top: 4;
    padding-bottom: 4;
    padding-top: 32px;
    padding-bottom: 32px;
}