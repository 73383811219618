.badgeContainer {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    flex-shrink: 0;
}

.myBadge {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    box-sizing: border-box;
    font-size: 12px;
    min-width: 20px;
    line-height: 1px;
    padding: 0 6px;
    height: 20px;
    border-radius: 10px;
    /*z-index: 1;*/
    top: 0;
    right: 0;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
    color: white;
    background-color: #3f51b5;
}